<template>
    <div>
      <top-bar :title="'报修详情'" :left="true"></top-bar>
      <van-tabs>
        <van-tab title="报修详情" class="info">
          <van-cell-group>
            <van-cell>
              <template #title>
                <div class="blue-box"></div>
                <span class="custom-title">报修信息</span>
              </template>
            </van-cell>
            <van-cell title="报修来源" :value="detailData.sourceStr" />
            <van-cell title="报修人姓名" :value="detailData.userName" />
            <van-cell title="联系电话" :value="detailData.userMobile" />
            <van-cell title="期望服务时间" :value="detailData.expectedServiceTime" />
            <van-cell title="报修进度">
              <template slot="default">
                <span :style="{color:repairData.status==0||repairData.status==10?'#ED4747':repairData.status==20?'#23D87B':repairData.status==30?'#387FF5':'#0AB9B3'}">
                  {{detailData.solveStatusStr}}
                </span>
              </template>
            </van-cell>
            <van-cell title="所在小区" :value="detailData.subareaName" />
            <van-cell title="报修性质" :value="detailData.publicPlaceStr" />
            <van-cell title="位置" v-if="detailData.fullLocation" :value="detailData.fullLocation" />
            <van-cell title="报修类别" :value="detailData.repairTypeStr" />
            <van-field v-model="detailData.repairDes" readonly autosize type="textarea" :border="false" placeholder="输入报修描述" class="left" />
            <van-row class="uploadBox">
              <van-col :span="24">
                <van-uploader v-model="fileList" :deletable="false" readonly :show-upload="false"/>
              </van-col>
            </van-row>
          </van-cell-group>
        </van-tab>

        <van-tab title="处理详情" class="info" v-if="repairData.status != 10 && repairData.status != 20">
          <van-cell-group>
            <van-cell>
              <template #title>
                <div class="blue-box"></div>
                <span class="custom-title">处理详情</span>
              </template>
            </van-cell>
            <van-field v-model="detailData.solveUserStr" readonly label="处理人" placeholder="请输入" input-align="right"/>
            <van-field v-model="detailData.solveUserMobile" readonly label="联系电话" placeholder="请输入" input-align="right"/>
            <van-field v-model="detailData.solveTime" readonly v-if="detailData.solveStatus!=0" label="处理时间" placeholder="请输入" input-align="right"/>
            <van-field v-model="detailData.solveCost" readonly v-if="detailData.solveStatus!=0" label="收费" placeholder="请输入" input-align="right"/>
            <van-field v-model="detailData.solveDes" :border="false" readonly placeholder="请输入" class="left" rows="3" type="textarea"/>
            <van-row class="uploadBox">
              <van-col :span="24">
                <van-uploader v-model="detailData.answerFileDisplayVOs" :deletable="false" readonly :show-upload="false" />
              </van-col>
            </van-row>
          </van-cell-group>
        </van-tab>
      </van-tabs>

      <van-row v-if="repairData.status == 10 || repairData.status == 20" class="btns">
        <van-row gutter="10">
          <van-col :span="12">
            <van-button type="info" size="large" round @click="change">编辑</van-button>
          </van-col>
          <van-col :span="12">
            <van-button plain type="info" size="large" round @click="handling" style="background-color:transparent;color:#FFA93A;border-color:#FFA93A">处理</van-button>
          </van-col>
        </van-row>
      </van-row>

    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import { mapState,mapMutations } from 'vuex'

export default {
  data () {
    return {
      value: '',
      detailData: {},
      fileList:[]
    }
  },
  computed:{...mapState(['repairData'])},
  components :{
    topBar
  },
  methods: {
    ...mapMutations(['setRepairHandling']),
    getDetail () {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/commonApp/repair/info/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.repairData.id
        })
      }).then(({data})=> {
          if (data.code == 0) {
            if (data.repairInfoEntityVO.fileDisplayVOs && data.repairInfoEntityVO.fileDisplayVOs.length != 0) {
            data.repairInfoEntityVO.fileDisplayVOs.forEach(item=> {
              let u;
              if (item.fileType == 'image') {
                u = getImageStream(item.relativeUrl)
                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.fileType,
                  url: u,
                  realUrl: item.relativeUrl.replace(/\\/g, "/")
                }
                this.fileList.push(url);
              }
            })
            }
            this.detailData = {
              ...data.repairInfoEntityVO,
              sourceStr: data.repairInfoEntityVO.source == 1 ? '社区自查': data.repairInfoEntityVO.source == 2 ? '居民反馈': data.repairInfoEntityVO.source == 3
                ? '上级督查' : '未知',
              expectedServiceTime: data.repairInfoEntityVO.expectedServiceTime || '无期望时间'
            }
          this.$toast.clear()
          } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
        })
    },
    change () {
      this.$router.push('/repair-change')
    },
    handling () {
      this.$store.commit('setrepairHandling',{})
      this.$router.push('/repair-handling')
    }
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.getDetail()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
